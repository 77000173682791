<script>
export default {
  name: 'Index',
};
</script>

<style scoped>

</style>

<template>
  <router-view />
</template>
